"use client";
import { Link } from "@remix-run/react";
import { Container } from "~/components/landingpage/Container";

export const Footer = () => {
  return (
    <footer className="border-t border-gray-200 bg-gray-50">
      <Container className="py-8">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="mb-4 md:mb-0">
            <span className="text-gray-700">
              © 2024 AIFred. Alle Rechte vorbehalten.
            </span>
          </div>
          <div className="flex gap-6">
            <Link
              to="/agb"
              className="text-gray-600 transition-colors hover:text-gray-900"
            >
              AGB
            </Link>
            <Link
              to="/datenschutz"
              className="text-gray-600 transition-colors hover:text-gray-900"
            >
              Datenschutzerklärung
            </Link>
            <Link
              to="/impressum"
              className="text-gray-600 transition-colors hover:text-gray-900"
            >
              Impressum
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
};
